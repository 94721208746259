.registre-card {
  cursor: pointer;
}

.accordion .card .card-header {
  background-color: white;
  cursor: pointer;
}

.song-card .card-header {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 5px;
}

.song-card .song-name {
  padding-top: 12px;
  padding-bottom: 12px;
}

.btn-dl-song {
  margin-top: 8.5px;
}
